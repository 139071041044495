var render = function () {
  var _vm$dataParse, _vm$item6, _vm$item$data$calorie2, _vm$item7, _vm$item7$data, _vm$item8, _vm$item8$data, _vm$item9, _vm$item9$data, _vm$item10, _vm$item10$data;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "position": "relative",
      "width": "100%",
      "height": "100%",
      "flex-direction": "column",
      "justify-content": "center",
      "margin-inline": "auto",
      "background-color": ['#F2F2F2', '#FFF'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['1rem 1rem 80px 1rem', '1.5rem'],
      "min-height": ['calc(100vh - 62px)', 'auto']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": _vm.breadcrumbLabel
    }
  })], 1), _vm.isLoadingData || _vm.isLoadingPreview ? _c('c-box', [_c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "padding": "128px 24px"
    }
  }, [_c('c-spinner', {
    attrs: {
      "thickness": "4px",
      "speed": "0.65s",
      "empty-color": "green.200",
      "color": "primary.400",
      "size": "xl",
      "margin-bottom": "16px"
    }
  }), _c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-weight": "500"
    }
  }, [_vm._v(" Memuat data Rekomendasi Menu... ")])], 1)], 1) : !_vm.isHaveMealPlanMonths ? _c('EmptyState', {
    attrs: {
      "title": "Kamu belum memiliki program aktif",
      "description": "Yuk beli program agar mendapatkan panduan makan yang sesuai untukmu.",
      "button-text": "Beli Program",
      "button-route": "client.select-program"
    }
  }) : !((_vm$dataParse = _vm.dataParse) !== null && _vm$dataParse !== void 0 && _vm$dataParse.length) ? _c('EmptyState', {
    attrs: {
      "title": "Rekomendasi menumu masih kosong",
      "description": "Ahli gizi kami masih merencanakan panduan makan terbaik buat kamu. Mohon ditunggu, ya!",
      "button-text": "Beranda",
      "button-route": "client.index"
    }
  }) : _c('c-flex', {
    attrs: {
      "background-color": "white",
      "padding": ['16px', '0'],
      "width": "100%",
      "flex-direction": "column",
      "gap": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "2rem",
      "align-items": "center",
      "margin-bottom": ['0.5rem', '1rem']
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "padding": "0px",
      "width": "30px",
      "height": "30px",
      "min-width": "30x",
      "display": ['none', 'flex'],
      "align-items": "center"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-chevron-left.svg'),
      "height": "20px",
      "width": "20px"
    }
  })], 1), _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "display": ['none', 'block'],
      "color": "neutral.black",
      "font-size": "20px",
      "font-weight": "700",
      "text-align": "center"
    }
  }, [_vm._v(" Jam Makan ")])], 1)], 1), _c('c-box', [_c('c-box', {
    staticClass: "client_meal-plan_menu-recommendation_2",
    class: _vm.isTourActive ? 'pointer-events-none' : '',
    attrs: {
      "margin-bottom": "1rem"
    }
  }, [_c('ChipFilter', {
    attrs: {
      "options": _vm.optionWeeks,
      "selected-filter": _vm.selectedWeek,
      "justify-content": "flex-start",
      "min-width": ['10px', '180px']
    },
    on: {
      "on-change-filter": _vm.onChangeWeek
    }
  })], 1), _c('c-flex', {
    staticClass: "client_meal-plan_menu-recommendation_3",
    class: _vm.isTourActive ? 'pointer-events-none' : '',
    attrs: {
      "width": "100%",
      "align-items": "center",
      "gap": "0px",
      "margin-bottom": "1rem"
    }
  }, [_c('ChipDays', {
    attrs: {
      "start-at": _vm.optionsDays.startAt,
      "end-at": _vm.optionsDays.endAt,
      "selected-day": _vm.day,
      "invalid-day-validation": _vm.optionsDays.validation,
      "custom-item-min-width": "30px"
    },
    on: {
      "on-change-day": _vm.onChangeDay
    }
  }), _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align-items": "center",
      "padding-left": "16px"
    }
  }, [_c('MealCalendar', {
    attrs: {
      "rows": 2,
      "columns": 1,
      "step": 2,
      "attributes": _vm.calendarAttributes,
      "min-date": _vm.minDate,
      "max-date": _vm.maxDate,
      "from-date": _vm.fromDate,
      "mask": _vm.masks,
      "model-config": _vm.modelConfig
    },
    on: {
      "update": function update(el) {
        return _vm.onChangeCalendar(el);
      }
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "width": "100%",
      "gap": "12px"
    }
  }, _vm._l(_vm.dataParse, function (menu) {
    return _c('CardInfoMenuRecommendation', {
      key: menu.id,
      attrs: {
        "item": menu,
        "is-tour-active": _vm.isTourActive
      },
      on: {
        "open-menu-recommendation": _vm.onOpenMenuRecommendation
      }
    });
  }), 1)], 1)], 1), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalMenuRecommendation && !['sm', 'md'].includes(_vm.currentBreakpoint),
      "close-on-overlay-click": false,
      "with-button-close": false,
      "has-footer": false,
      "size": "800px"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        var _vm$item, _vm$item$data$calorie, _vm$item2, _vm$item2$data, _vm$item3, _vm$item3$data, _vm$item4, _vm$item4$data;

        return [_c('c-flex', {
          attrs: {
            "width": "100%",
            "justify-content": "space-between",
            "align-items": "center",
            "padding": "16px 20px",
            "border-bottom-width": "1px"
          }
        }, [_c('c-text', {
          attrs: {
            "font-size": ['14px', '24px'],
            "font-weight": "600",
            "color": "neutral.black"
          }
        }, [_vm._v(" " + _vm._s((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.label) + " - " + _vm._s((_vm$item$data$calorie = (_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : (_vm$item2$data = _vm$item2.data) === null || _vm$item2$data === void 0 ? void 0 : _vm$item2$data.caloriesAmount) !== null && _vm$item$data$calorie !== void 0 ? _vm$item$data$calorie : 0) + " kkal ")]), _c('c-flex', {
          attrs: {
            "align-items": "center",
            "gap": "12px"
          }
        }, [_c('c-text', {
          attrs: {
            "font-size": ['12px', '16px'],
            "color": "neutral.superDarkGray",
            "font-weight": ['400', '500']
          }
        }, [_vm._v(" " + _vm._s(_vm.format24Time((_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : (_vm$item3$data = _vm$item3.data) === null || _vm$item3$data === void 0 ? void 0 : _vm$item3$data.startTime)) + " - " + _vm._s(_vm.format24Time((_vm$item4 = _vm.item) === null || _vm$item4 === void 0 ? void 0 : (_vm$item4$data = _vm$item4.data) === null || _vm$item4$data === void 0 ? void 0 : _vm$item4$data.endTime)) + " ")]), _c('c-button', {
          attrs: {
            "variant": "ghost",
            "width": ['20px', '30px'],
            "min-width": ['20px', '30px'],
            "height": ['20px', '30px'],
            "margin": "0",
            "padding": "0"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalMenuRecommendation = false;
            }
          }
        }, [_c('c-icon', {
          attrs: {
            "name": "close",
            "size": ['13px', '17px']
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$item5, _vm$item5$data;

        return [_c('c-box', {
          staticClass: "client_meal-plan_menu-recommendation_5",
          attrs: {
            "width": "100%",
            "max-height": "600px",
            "overflow": "auto"
          }
        }, [_c('c-box', {
          attrs: {
            "margin-inline": "auto",
            "max-width": "700px",
            "padding-inline": "20px",
            "padding-bottom": "16px"
          }
        }, _vm._l((_vm$item5 = _vm.item) === null || _vm$item5 === void 0 ? void 0 : (_vm$item5$data = _vm$item5.data) === null || _vm$item5$data === void 0 ? void 0 : _vm$item5$data.dataMenu, function (menu, i) {
          var _menu$menus, _menu$menus2, _menu$menus3, _menu$menus4, _menu$menus5;

          return _c('c-box', {
            key: i
          }, [(menu === null || menu === void 0 ? void 0 : menu.menuType) === 'singleEntry' ? _c('c-flex', {
            attrs: {
              "width": "100%",
              "flex-direction": "column",
              "border-bottom-width": "1px",
              "border-bottom-color": "#55565",
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.onClickMenuItem(_vm.subMenu, menu === null || menu === void 0 ? void 0 : menu.menuType);
              }
            }
          }, [_c('c-flex', {
            attrs: {
              "width": "100%",
              "padding-block": "8px",
              "align-items": "center",
              "gap": "16px"
            }
          }, [_c('c-image', {
            attrs: {
              "src": menu !== null && menu !== void 0 && (_menu$menus = menu.menus) !== null && _menu$menus !== void 0 && _menu$menus.photoUrl ? menu === null || menu === void 0 ? void 0 : (_menu$menus2 = menu.menus) === null || _menu$menus2 === void 0 ? void 0 : _menu$menus2.photoUrl : 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250',
              "width": ['60px', '100px'],
              "height": ['60px', '100px'],
              "object-fit": "cover",
              "object-position": "center",
              "border-radius": "8px"
            }
          }), _c('c-text', {
            attrs: {
              "font-size": ['12px', '16px'],
              "font-weight": "500",
              "padding-block": "16px"
            }
          }, [_vm._v(" " + _vm._s(menu === null || menu === void 0 ? void 0 : (_menu$menus3 = menu.menus) === null || _menu$menus3 === void 0 ? void 0 : _menu$menus3.servingSize) + " " + _vm._s(menu === null || menu === void 0 ? void 0 : (_menu$menus4 = menu.menus) === null || _menu$menus4 === void 0 ? void 0 : _menu$menus4.servingSizeUnit) + " " + _vm._s(menu === null || menu === void 0 ? void 0 : menu.menuName) + " ")])], 1)], 1) : _c('c-flex', {
            attrs: {
              "width": "100%",
              "flex-direction": "column",
              "border-bottom-width": "1px",
              "border-bottom-color": "#55565"
            }
          }, [_c('c-flex', {
            attrs: {
              "width": "100%",
              "padding-top": "16px",
              "padding-bottom": "8px",
              "align-items": "center",
              "gap": "16px"
            }
          }, [_c('c-text', {
            attrs: {
              "font-size": ['12px', '16px'],
              "font-weight": "500"
            }
          }, [_vm._v(" " + _vm._s(menu === null || menu === void 0 ? void 0 : menu.menuName) + " ")])], 1), _c('c-box', [_c('c-text', {
            attrs: {
              "font-size": ['10px', '14px'],
              "color": "neutral.superDarkGray",
              "margin-bottom": "8px"
            }
          }, [_vm._v(" Komposisi Makanan ")]), (menu === null || menu === void 0 ? void 0 : (_menu$menus5 = menu.menus) === null || _menu$menus5 === void 0 ? void 0 : _menu$menus5.length) > 0 ? _c('c-grid', {
            attrs: {
              "template-columns": "repeat(5, 1fr)",
              "gap": "16px",
              "padding-bottom": "8px"
            }
          }, _vm._l(menu === null || menu === void 0 ? void 0 : menu.menus, function (subMenu, j) {
            return _c('c-flex', {
              key: j,
              attrs: {
                "justify-content": "flex-start",
                "align-items": "center",
                "flex-direction": "column",
                "cursor": "pointer"
              },
              on: {
                "click": function click($event) {
                  return _vm.onClickMenuItem(subMenu, menu === null || menu === void 0 ? void 0 : menu.menuType);
                }
              }
            }, [_c('c-image', {
              attrs: {
                "src": subMenu !== null && subMenu !== void 0 && subMenu.photoUrl ? subMenu === null || subMenu === void 0 ? void 0 : subMenu.photoUrl : 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250',
                "width": ['60px', '100px'],
                "height": ['60px', '100px'],
                "object-fit": "cover",
                "object-position": "center",
                "border-radius": "8px",
                "margin-bottom": "8px"
              }
            }), _c('c-text', {
              attrs: {
                "font-size": ['10px', '14px'],
                "color": "neutral.superDarkGray",
                "text-align": "center"
              }
            }, [_vm._v(" " + _vm._s(subMenu === null || subMenu === void 0 ? void 0 : subMenu.servingSize) + " " + _vm._s(subMenu === null || subMenu === void 0 ? void 0 : subMenu.servingSizeUnit) + " " + _vm._s(subMenu === null || subMenu === void 0 ? void 0 : subMenu.foodName) + " ")])], 1);
          }), 1) : _c('c-box', [_vm._v(" - ")])], 1)], 1)], 1);
        }), 1)], 1)];
      },
      proxy: true
    }])
  }), _c('c-drawer', {
    attrs: {
      "placement": "bottom",
      "on-close": function onClose() {
        return _vm.isOpenModalMenuRecommendation = false;
      },
      "is-open": _vm.isOpenModalMenuRecommendation && ['sm', 'md'].includes(_vm.currentBreakpoint)
    }
  }, [_c('c-drawer-overlay'), _c('c-drawer-content', {
    attrs: {
      "border-top-left-radius": "16px",
      "border-top-right-radius": "16px"
    }
  }, [_c('c-drawer-header', {
    attrs: {
      "border-bottom-width": "1px",
      "padding-inline": "12px",
      "padding-block": "12px"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '24px'],
      "font-weight": "600",
      "color": "neutral.black"
    }
  }, [_vm._v(" " + _vm._s((_vm$item6 = _vm.item) === null || _vm$item6 === void 0 ? void 0 : _vm$item6.label) + " - " + _vm._s((_vm$item$data$calorie2 = (_vm$item7 = _vm.item) === null || _vm$item7 === void 0 ? void 0 : (_vm$item7$data = _vm$item7.data) === null || _vm$item7$data === void 0 ? void 0 : _vm$item7$data.caloriesAmount) !== null && _vm$item$data$calorie2 !== void 0 ? _vm$item$data$calorie2 : 0) + " kkal ")]), _c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "12px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "color": "neutral.superDarkGray",
      "font-weight": ['400', '500']
    }
  }, [_vm._v(" " + _vm._s(_vm.format24Time((_vm$item8 = _vm.item) === null || _vm$item8 === void 0 ? void 0 : (_vm$item8$data = _vm$item8.data) === null || _vm$item8$data === void 0 ? void 0 : _vm$item8$data.startTime)) + " - " + _vm._s(_vm.format24Time((_vm$item9 = _vm.item) === null || _vm$item9 === void 0 ? void 0 : (_vm$item9$data = _vm$item9.data) === null || _vm$item9$data === void 0 ? void 0 : _vm$item9$data.endTime)) + " ")]), _c('c-button', {
    attrs: {
      "variant": "ghost",
      "width": ['20px', '30px'],
      "min-width": ['20px', '30px'],
      "height": ['20px', '30px'],
      "margin": "0",
      "padding": "0"
    },
    on: {
      "click": function click($event) {
        _vm.isOpenModalMenuRecommendation = false;
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "close",
      "size": ['13px', '17px']
    }
  })], 1)], 1)], 1)], 1), _c('c-drawer-body', {
    attrs: {
      "padding-inline": "12px",
      "padding-block": "0px"
    }
  }, [_c('c-box', {
    staticClass: "client_meal-plan_menu-recommendation_5",
    attrs: {
      "width": "100%",
      "max-height": "600px",
      "overflow": "auto"
    }
  }, [_c('c-box', {
    attrs: {
      "margin-inline": "auto",
      "max-width": "700px",
      "padding-inline": "0px",
      "padding-bottom": "8px"
    }
  }, _vm._l((_vm$item10 = _vm.item) === null || _vm$item10 === void 0 ? void 0 : (_vm$item10$data = _vm$item10.data) === null || _vm$item10$data === void 0 ? void 0 : _vm$item10$data.dataMenu, function (menu, i) {
    var _menu$menus6, _menu$menus7, _menu$menus8, _menu$menus9, _menu$menus10;

    return _c('c-box', {
      key: i
    }, [(menu === null || menu === void 0 ? void 0 : menu.menuType) === 'singleEntry' ? _c('c-flex', {
      attrs: {
        "width": "100%",
        "flex-direction": "column",
        "border-bottom-width": "1px",
        "border-bottom-color": "#55565",
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onClickMenuItem(menu, menu === null || menu === void 0 ? void 0 : menu.menuType);
        }
      }
    }, [_c('c-flex', {
      attrs: {
        "width": "100%",
        "padding-block": "8px",
        "align-items": "center",
        "gap": "16px"
      }
    }, [_c('c-image', {
      attrs: {
        "src": menu !== null && menu !== void 0 && (_menu$menus6 = menu.menus) !== null && _menu$menus6 !== void 0 && _menu$menus6.photoUrl ? menu === null || menu === void 0 ? void 0 : (_menu$menus7 = menu.menus) === null || _menu$menus7 === void 0 ? void 0 : _menu$menus7.photoUrl : 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250',
        "width": ['60px', '100px'],
        "height": ['60px', '100px'],
        "object-fit": "cover",
        "object-position": "center",
        "border-radius": "8px"
      }
    }), _c('c-text', {
      attrs: {
        "font-size": ['12px', '16px'],
        "font-weight": "500",
        "padding-block": "16px"
      }
    }, [_vm._v(" " + _vm._s(menu === null || menu === void 0 ? void 0 : (_menu$menus8 = menu.menus) === null || _menu$menus8 === void 0 ? void 0 : _menu$menus8.servingSize) + " " + _vm._s(menu === null || menu === void 0 ? void 0 : (_menu$menus9 = menu.menus) === null || _menu$menus9 === void 0 ? void 0 : _menu$menus9.servingSizeUnit) + " " + _vm._s(menu === null || menu === void 0 ? void 0 : menu.menuName) + " ")])], 1)], 1) : _c('c-flex', {
      attrs: {
        "width": "100%",
        "flex-direction": "column",
        "border-bottom-width": "1px",
        "border-bottom-color": "#55565"
      }
    }, [_c('c-flex', {
      attrs: {
        "width": "100%",
        "padding-top": ['8px', '16px'],
        "padding-bottom": "8px",
        "align-items": "center",
        "gap": "16px"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['12px', '16px'],
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s(menu === null || menu === void 0 ? void 0 : menu.menuName) + " ")])], 1), _c('c-box', [_c('c-text', {
      attrs: {
        "font-size": ['10px', '14px'],
        "color": "neutral.superDarkGray",
        "margin-bottom": "8px"
      }
    }, [_vm._v(" Komposisi Makanan ")]), (menu === null || menu === void 0 ? void 0 : (_menu$menus10 = menu.menus) === null || _menu$menus10 === void 0 ? void 0 : _menu$menus10.length) > 0 ? _c('c-grid', {
      attrs: {
        "template-columns": "repeat(5, 1fr)",
        "gap": "16px",
        "padding-bottom": "8px"
      }
    }, _vm._l(menu === null || menu === void 0 ? void 0 : menu.menus, function (subMenu, j) {
      return _c('c-flex', {
        key: j,
        attrs: {
          "justify-content": "flex-start",
          "align-items": "center",
          "flex-direction": "column",
          "cursor": "pointer"
        },
        on: {
          "click": function click($event) {
            return _vm.onClickMenuItem(subMenu, menu === null || menu === void 0 ? void 0 : menu.menuType);
          }
        }
      }, [_c('c-image', {
        attrs: {
          "src": subMenu !== null && subMenu !== void 0 && subMenu.photoUrl ? subMenu === null || subMenu === void 0 ? void 0 : subMenu.photoUrl : 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250',
          "width": ['60px', '100px'],
          "height": ['60px', '100px'],
          "object-fit": "cover",
          "object-position": "center",
          "border-radius": "8px",
          "margin-bottom": "8px"
        }
      }), _c('c-text', {
        attrs: {
          "font-size": ['10px', '14px'],
          "color": "neutral.superDarkGray",
          "text-align": "center"
        }
      }, [_vm._v(" " + _vm._s(subMenu === null || subMenu === void 0 ? void 0 : subMenu.servingSize) + " " + _vm._s(subMenu === null || subMenu === void 0 ? void 0 : subMenu.servingSizeUnit) + " " + _vm._s(subMenu === null || subMenu === void 0 ? void 0 : subMenu.foodName) + " ")])], 1);
    }), 1) : _c('c-box', [_vm._v(" - ")])], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }